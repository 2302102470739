<template>
  <div>
    <div v-for="addon in addons" :key="addon.id" class="ma-4">
      <AddOn
        :addon="addon"
        v-on="$listeners"
        :isEdit="isEdit"
        :selectedAddons="selectedAddons"
      />
    </div>
  </div>
</template>

<script>
import AddOn from "@/components/AddOn";
export default {
  name: "AddOns",
  components: {
    AddOn,
  },
  props: {
    addons: {
      type: Array,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    selectedAddons: {
      required: false,
    },
  },
};
</script>
