<template>
  <div>
    <div v-if="this.scopedProducts.length == 0 && loading">
      <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
    </div>
    <div v-else>
      <div class="d-flex justify-space-between">
        <p class="text--secondary mb-1 d-flex flex-wrap">
          {{ addon.displayName }}
          <span class="text--disabled ml-0 ml-md-2">
            ({{ addon | verbiage }})
          </span>
        </p>
        <v-chip
          v-if="addon.required"
          small
          class="px-4 font-weight-bold text-caption"
        >
          Obligatorio
        </v-chip>
      </div>
      <v-list>
        <v-list-item-group
          v-if="
            addon.controlType == undefined ||
              addon.controlType == 'radio' ||
              addon.controlType == 'checkbox'
          "
          v-model="newAddOn.selectedAddOnProduct"
          @change="addAddOnToCart()"
          :multiple="
            addon.max != 1 ||
              addon.required == undefined ||
              addon.required == false
          "
          :max="checkMaxAddOn(addon.max, scopedProducts)"
        >
          <template v-for="product in scopedProducts">
            <v-list-item
              :key="product.id"
              :value="product.id"
              active-class="primary--text"
              class="px-0"
            >
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-title class="d-flex justify-space-between my-2">
                    <span class="flex-grow-1 text-truncate">
                      {{ product.name }}
                    </span>
                    <span class="orange--text text--darken-4">
                      {{ getPrice(product) | toCurrency }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action class="ml-2">
                  <v-icon v-if="!active" color="primary lighten-1">
                    far fa-circle
                  </v-icon>
                  <v-icon v-else color="primary lighten-1">
                    far fa-check-circle
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>

        <div v-else>
          <v-list-item
            v-for="product in scopedProducts"
            :key="product.id"
            class="px-0"
          >
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-space-between my-2">
                <span class="flex-grow-1 text-truncate">
                  {{ product.name }}
                </span>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="ml-2">
              <SpinAddOn
                @check-addon-count="checkAddOn"
                :allow-add="allowAdd"
                :allow-subtract="allowSubtract"
                :product="product"
                :isEdit="isEdit"
                :selectedAddOnProducts="updateProductCount(product.id)"
              />
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SpinAddOn from "@/components/SpinAddOn";
const fb = require("@/firebase.js");
export default {
  components: {
    SpinAddOn,
  },
  data() {
    return {
      scopedProducts: [],
      loading: true,
      newAddOn: {
        id: this.addon.id,
        parentProductId: this.addon.parentProductId,
        displayName: this.addon.displayName,
        selectedAddOnProduct: [],
        price: 0,
        required: this.addon.required,
        max: this.addon.max,
        min: this.addon.min,
        controlType: this.addon.controlType,
      },
      allowAdd: true,
      allowSubtract: false,
      count: 0,
    };
  },
  props: {
    addon: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    selectedAddons: [Object, Array, String],
  },
  async mounted() {
    await this.getAddOnProducts();
    this.setSelectedAddonProducts();
    this.loading = false;
  },
  filters: {
    verbiage: function(value) {
      if (!value.max) return "opcional";

      if (value.controlType == "spin") {
        return `min:${value.min} - max:${value.max}`;
      } else {
        if (value.max == 1) {
          return "elige uno";
        } else {
          return `max:${value.max}`;
        }
      }
    },
    toCurrency(value) {
      if (typeof value !== "number") return value;
      const formatter = new Intl.NumberFormat("es-HN", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      return formatter.format(value);
    },
  },
  methods: {
    getAddOnProducts() {
      if (this.scopedProducts.length == 0) {
        fb.db
          .collection(`addons/${this.addon.id}/products`)
          .where("active", "==", true)
          .where("deleted", "==", false)
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach(async (doc) => {
              await this.getProducts(doc.data());
            });
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      }
    },
    getProducts(product) {
      let tempObj = {};
      fb.db
        .collection("products")
        .doc(product.productId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.scopedProducts.push(
              Object.assign({ id: doc.id, fromAddOn: product }, doc.data())
            );
            tempObj = Object.assign({ id: doc.id }, doc.data());
          }
        })
        .then(() => {
          this.$store.commit("setAddonProducts", tempObj);
        });
    },
    addAddOnToCart() {
      this.$emit("add-add-on", this.newAddOn);
    },
    getPrice(product) {
      if (product.fromAddOn.overridePrice) {
        this.newAddOn.price = product.fromAddOn.price;
        return product.fromAddOn.price;
      } else {
        this.newAddOn.price = product.price;
        return product.price;
      }
    },
    checkAddOn(unit, product) {
      this.checkCount(unit);
      this.pushOrSpliceById(unit, product);
      this.addAddOnToCart();
    },
    checkCount(unit) {
      this.count = this.count + unit;

      if (this.count == this.addon.max) this.allowAdd = false;
      else this.allowAdd = true;

      if (this.count == 0) this.allowSubtract = false;
      else this.allowSubtract = true;
    },
    pushOrSpliceById(unit, item) {
      if (unit < 0) {
        let index = this.newAddOn.selectedAddOnProduct.findIndex(
          (_item) => _item.id == item.id
        );
        if (index != -1) {
          this.newAddOn.selectedAddOnProduct.splice(index, 1);
        }
      } else {
        this.newAddOn.selectedAddOnProduct.push(item);
      }
    },
    updateProductCount(productId) {
      if (this.isEdit) {
        let parsedSelected = JSON.parse(JSON.stringify(this.selectedAddons));

        let selectedAddonAlt = parsedSelected.filter((selectedAddon) => {
          return selectedAddon.id == this.addon.id;
        });

        return selectedAddonAlt[0].selectedAddOnProduct.filter(
          (selectedProduct) => {
            return selectedProduct.id == productId;
          }
        );
      } else {
        return [];
      }
    },
    setSelectedAddonProducts() {
      if (this.isEdit) {
        let parsedSelected = JSON.parse(JSON.stringify(this.selectedAddons));

        let selectedAddonAlt = parsedSelected.filter((selectedAddon) => {
          return selectedAddon.id == this.addon.id;
        });

        if (selectedAddonAlt.length != 0) {
          if (typeof selectedAddonAlt[0].selectedAddOnProduct == "string") {
            this.newAddOn.selectedAddOnProduct =
              selectedAddonAlt[0].selectedAddOnProduct;
            this.count = 1;
          } else {
            selectedAddonAlt[0].selectedAddOnProduct.forEach(
              (selectedProduct) => {
                this.newAddOn.selectedAddOnProduct.push(selectedProduct);
              }
            );
            this.count = selectedAddonAlt[0].selectedAddOnProduct.length;
          }
        }
        this.allowSubtract = true;
        this.allowAdd = false;
      }
    },
    checkMaxAddOn(max, products) {
      if (max === 0 || max == undefined) {
        return products.length;
      } else {
        return max;
      }
    },
  },
  computed: {
    ...mapState(["addOnsProductStore"]),
  },
};
</script>
