var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.scopedProducts.length == 0 && _vm.loading)?_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"list-item"}})],1):_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"text--secondary mb-1 d-flex flex-wrap"},[_vm._v(" "+_vm._s(_vm.addon.displayName)+" "),_c('span',{staticClass:"text--disabled ml-0 ml-md-2"},[_vm._v(" ("+_vm._s(_vm._f("verbiage")(_vm.addon))+") ")])]),(_vm.addon.required)?_c('v-chip',{staticClass:"px-4 font-weight-bold text-caption",attrs:{"small":""}},[_vm._v(" Obligatorio ")]):_vm._e()],1),_c('v-list',[(
          _vm.addon.controlType == undefined ||
            _vm.addon.controlType == 'radio' ||
            _vm.addon.controlType == 'checkbox'
        )?_c('v-list-item-group',{attrs:{"multiple":_vm.addon.max != 1 ||
            _vm.addon.required == undefined ||
            _vm.addon.required == false,"max":_vm.checkMaxAddOn(_vm.addon.max, _vm.scopedProducts)},on:{"change":function($event){return _vm.addAddOnToCart()}},model:{value:(_vm.newAddOn.selectedAddOnProduct),callback:function ($$v) {_vm.$set(_vm.newAddOn, "selectedAddOnProduct", $$v)},expression:"newAddOn.selectedAddOnProduct"}},[_vm._l((_vm.scopedProducts),function(product){return [_c('v-list-item',{key:product.id,staticClass:"px-0",attrs:{"value":product.id,"active-class":"primary--text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-space-between my-2"},[_c('span',{staticClass:"flex-grow-1 text-truncate"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('span',{staticClass:"orange--text text--darken-4"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getPrice(product)))+" ")])])],1),_c('v-list-item-action',{staticClass:"ml-2"},[(!active)?_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v(" far fa-circle ")]):_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v(" far fa-check-circle ")])],1)]}}],null,true)})]})],2):_c('div',_vm._l((_vm.scopedProducts),function(product){return _c('v-list-item',{key:product.id,staticClass:"px-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-space-between my-2"},[_c('span',{staticClass:"flex-grow-1 text-truncate"},[_vm._v(" "+_vm._s(product.name)+" ")])])],1),_c('v-list-item-action',{staticClass:"ml-2"},[_c('SpinAddOn',{attrs:{"allow-add":_vm.allowAdd,"allow-subtract":_vm.allowSubtract,"product":product,"isEdit":_vm.isEdit,"selectedAddOnProducts":_vm.updateProductCount(product.id)},on:{"check-addon-count":_vm.checkAddOn}})],1)],1)}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }