<template>
  <div class="main-container">
    <v-overlay v-if="loading" class="loading-center">
      <Lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-container
      fluid
      v-if="product && !loading"
      class="screen-width pt-0 pt-md-4 mb-12"
    >
      <v-snackbar top :timeout="3000" v-model="snackbar" rounded="pill">
        <span>{{ snackbarText }}</span>
      </v-snackbar>
      <v-row>
        <v-col class="pa-0">
          <v-hover>
            <div class="button-wrapper">
              <v-btn
                color="white"
                absolute
                top
                right
                fab
                :small="$vuetify.breakpoint.mdAndDown"
                elevation="2"
                @click="$router.back()"
              >
                <v-icon color="primary">fas fa-chevron-left</v-icon>
              </v-btn>
            </div>
          </v-hover>
          <v-dialog width="500">
            <template v-slot:activator="{ on, attrs }">
              <img
                :src="product.images[0] || ochoLogoPlaceholder"
                :lazy-src="ochoLogoPlaceholder"
                class="grey lighten-2 cover-up rounded"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-card class="pa-5 rounded d-flex">
              <img
                :src="product.images[0] || ochoLogoPlaceholder"
                :lazy-src="ochoLogoPlaceholder"
                class="grey lighten-2 rounded-xl mx-auto dialogImg"
              />
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="text-h5 text-md-h4 text-center text--primary">
            {{ product.name }}
          </div>
          <p class="text-center primary--text mb-0">
            {{ product.description }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-0">
          <div v-if="scopeAddOnProduct(product.id).length != 0" class="mt-4">
            <AddOns
              :addons="scopeAddOnProduct(product.id)"
              @add-add-on="addAddOn"
              :isEdit="isEdit"
              :selectedAddons="this.$route.params.product.addOns"
            />
          </div>
          <div v-else>
            {{ allowSubmitDefault() }}
          </div>
        </v-col>
      </v-row>
      <v-row align-content="center">
        <v-col cols="12" md="4">
          <p class="text--secondary mb-0 ml-4 ml-md-0">
            Comentario
          </p>
        </v-col>
        <v-col cols="12" md="8">
          <v-textarea
            solo
            auto-grow
            name="input-7-2"
            label="Ingresa tu comentario aqui..."
            rounded
            v-model="newProduct.comment"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
    <v-footer fixed color="primary" class="pa-0 pa-md-1">
      <v-row align="center" class="px-2">
        <v-col class="text-center">
          <div class="counter rounded-pill d-flex justify-space-around">
            <v-icon small color="white" @click="decrement()">
              fas fa-minus
            </v-icon>
            <strong>{{ newProduct.quantity }}</strong>
            <v-icon small color="white" @click="increment()">
              fas fa-plus
            </v-icon>
          </div>
        </v-col>
        <v-col class="text-center">
          <v-chip color="white" @click="addToCart()">
            <span class="primary--text mr-2">
              <strong>
                {{ actionWord }}
              </strong>
            </span>
            <strong>{{ subTotal | toCurrency }}</strong>
          </v-chip>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { mapState } from "vuex";
import AddOns from "@/components/AddOns";

export default {
  name: "Product",
  components: {
    Lottie,
    AddOns,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      product: this.$route.params.product,
      requiredScopeAddOnProduct: [],
      actionWord: "Agregar",
      ochoLogoPlaceholder:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpeg?alt=media&token=063d2780-9ef0-4bce-bab6-aac516fe6d28",
      newProduct: {
        quantity: 1,
        comment: "",
        addOns: [],
      },
      snackbarText: "",
      snackbar: false,
      allowSubmit: false,
      isEdit: this.$route.params.edit && this.$route.params.edit == true,
    };
  },
  mounted() {
    this.checkProduct();
    document.title = `Ocho | ${this.businessStore.shortName} | ${this.product.name}`;
    if (this.isEdit) {
      this.newProduct = {
        quantity: this.$route.params.product.quantity,
        comment: this.$route.params.product.comment,
        addOns: this.$route.params.product.addOns,
      };
      this.allowSubmit = true;
      this.actionWord = "Actualizar";
    }
    this.loading = false;
  },
  methods: {
    checkProduct() {
      if (!this.product) {
        this.$router.back();
      }
    },
    increment() {
      this.newProduct.quantity++;
    },
    decrement() {
      if (this.newProduct.quantity > 1) this.newProduct.quantity--;
    },
    scopeAddOnProduct(productId) {
      let tempAddOns = this.addOnsStore
        .filter((addOn) => {
          return addOn.parentProductId == productId;
        })
        .slice()
        .sort((a, b) => (a.position > b.position ? 1 : -1));

      this.requiredScopeAddOnProduct = tempAddOns.filter((addOn) => {
        return addOn.required == true;
      });
      return tempAddOns;
    },
    async addAddOn(addOn) {
      if (typeof addOn.selectedAddOnProduct == "string") {
        addOn.quantity = 1;
      } else {
        if (!addOn.selectedAddOnProduct) {
          addOn.quantity = 0;
        } else {
          addOn.quantity = addOn.selectedAddOnProduct.length;
        }
      }
      this.pushOrCreateById(addOn);
      this.cleanAddOnArray();
      await this.checkRequiredAddOns();
    },
    pushOrCreateById(item) {
      const i = this.newProduct.addOns.findIndex(
        (_item) => _item.id === item.id
      );
      if (i > -1) this.newProduct.addOns[i] = item;
      else this.newProduct.addOns.push(item);
    },
    cleanAddOnArray() {
      this.newProduct.addOns = this.newProduct.addOns.filter(
        (addOn) => addOn.quantity > 0
      );
    },
    checkRequiredAddOns() {
      // https://guillim.github.io/vue/2019/03/20/damn-vuejs-observer.html
      let reqAddonIds = this.requiredScopeAddOnProduct.map((item) => item.id);
      let parsedObj = JSON.parse(JSON.stringify(this.newProduct.addOns));
      let passedAddonIds = parsedObj
        .filter((item) => item.required == true)
        .map((item) => item.id);
      if (reqAddonIds.every((id) => passedAddonIds.includes(id))) {
        for (let reqAddon of this.requiredScopeAddOnProduct) {
          // this.requiredScopeAddOnProduct.forEach((reqAddon) => {})
          let tempAddOn = parsedObj.find((prop) => prop.id === reqAddon.id);
          // if (
          //   reqAddon.min <= tempAddOn.quantity &&
          //   reqAddon.max >= tempAddOn.quantity
          // )
          if (reqAddon.max == tempAddOn.quantity) {
            this.allowSubmit = true;
          } else {
            this.allowSubmit = false;
            break;
          }
        }
      } else {
        this.allowSubmit = false;
      }
    },
    addToCart() {
      let reqAddOnsArray = JSON.parse(
        JSON.stringify(this.requiredScopeAddOnProduct)
      );

      let productObj = Object.assign(
        {
          name: this.product.name,
          id: this.product.id,
          subTotalPrice: this.subTotal,
          price: this.product.price,
          images: this.product.images,
        },
        this.newProduct
      );

      if (reqAddOnsArray.length === 0 || this.allowSubmit == true) {
        if (this.isEdit) {
          let idx = this.$route.params.idx;
          let payload = { idx: idx, productObj: productObj };
          this.$store.commit("updateCart", payload);
        } else {
          this.$store.commit("addToCart", productObj);
        }
        this.$router.back();
      } else {
        this.snackbar = true;
        this.snackbarText = "Por favor llena todos los campos requeridos 😁";
      }
    },
    allowSubmitDefault() {
      this.allowSubmit = true;
    },
  },
  computed: {
    ...mapState(["businessStore", "addOnsStore"]),
    subTotal() {
      return this.newProduct.quantity * (this.product.price + this.extraCharge);
    },
    extraCharge() {
      return this.newProduct.addOns
        .filter((addon) => addon.price > 0)
        .reduce((accum, addon) => accum + addon.quantity * addon.price, 0);
    },
  },
  filters: {
    toCurrency(value) {
      if (typeof value !== "number") return value;
      const formatter = new Intl.NumberFormat("es-HN", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
};
</script>

<style scoped>
.cover-up {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
}
.cover-up:hover {
  border: 2px dashed #f06f3d;
}
@media only screen and (max-width: 600px) {
  .cover-up {
    max-height: 150px;
  }
}
.button-wrapper {
  position: absolute;
  margin-top: 3em;
  margin-left: 7.5em;
  opacity: 0.7;
}
@media only screen and (max-width: 600px) {
  .button-wrapper {
    margin-top: 2em;
    margin-left: 5.5em;
  }
}
.counter {
  border: white 2px solid;
  max-width: 12rem;
  margin: 0 auto;
}
.dialogImg {
  max-height: 25em;
  max-width: 25em;
  object-fit: cover;
}
@media only screen and (max-width: 600px) {
  .dialogImg {
    max-height: 10em;
    max-width: 10em;
  }
}
</style>
