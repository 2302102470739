<template>
  <div class="d-flex flex-row">
    <v-btn
      rounded
      x-small
      outlined
      :disabled="!allowSubtract"
      @click="decrementTotal()"
      color="grey darken-1"
    >
      <v-icon x-small>
        fas fa-minus
      </v-icon>
    </v-btn>
    <span class="px-2 font-weight-bold">
      {{ total }}
    </span>
    <v-btn
      rounded
      x-small
      outlined
      :disabled="!allowAdd"
      @click="incrementTotal()"
      color="grey darken-1"
    >
      <v-icon x-small>
        fas fa-plus
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SpinAddOn",
  props: [
    "allow-add",
    "allow-subtract",
    "product",
    "isEdit",
    "selectedAddOnProducts",
  ],
  data() {
    return {
      total: 0,
    };
  },
  mounted() {
    if (this.isEdit) {
      this.total = this.selectedAddOnProducts.length;
    }
  },
  methods: {
    incrementTotal() {
      this.total = this.total + 1;
      this.handleChange(1);
    },
    decrementTotal() {
      if (this.total > 0) {
        this.total = this.total - 1;
        this.handleChange(-1);
      }
    },
    handleChange(unit) {
      this.$emit("check-addon-count", unit, this.product);
    },
  },
};
</script>
